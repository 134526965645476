/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from "react";
import { SiteFilterProvider } from "./src/context/SiteFilterContext";

export const wrapRootElement = ({ element }) => (
  <SiteFilterProvider>{element}</SiteFilterProvider>
);
