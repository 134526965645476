import React, { createContext, useState, useEffect, useContext } from "react";
import _ from "lodash";
import { navigate } from "gatsby";

const SiteFilterContext = createContext();

export const useSiteFilter = () => useContext(SiteFilterContext);

export const SiteFilterProvider = ({ children }) => {
  const [homeTeam, setHomeTeam] = useState("");

  // useEffect(() => {
  //   const storedTeam = localStorage.getItem("homeTeam");
  //   if (storedTeam) {
  //     setHomeTeam(storedTeam);
  //   }
  // }, []);

  const updateHomeTeam = (team, location) => {
    setHomeTeam(team);
    // Save to localStorage for persistence
    // localStorage.setItem("homeTeam", team);
    localStorage.removeItem("homeTeam");

    const isRosterPage = _.get(location, "pathname", "").includes("/roster");
    if (isRosterPage) {
      if (team) {
        navigate(`/roster/${team.replace(/\s+/g, "-").toLowerCase()}`);
      } else {
        navigate(`/roster/`);
      }
    }
    const isSchedulePage = _.get(location, "pathname", "").includes(
      "/schedule"
    );

    if (isSchedulePage) {
      const searchParams = new URLSearchParams(location.search);
      if (team) {
        searchParams.set("team", team.replace(/\s+/g, "-").toLowerCase());
      } else {
        searchParams.delete("team");
      }
      navigate(`/schedule?${searchParams.toString()}`);
    } else {
      const searchParams = new URLSearchParams(location.search);
      setHomeTeam("");
      searchParams.delete("team");
    }
    // if (_.get(location, "pathname", "").includes("/news")) {
    //   navigate(`/news/team/${team.replace(/\s+/g, "-").toLowerCase()}`);
    // }
  };

  const filterDataByTeam = (data, team) => {
    if (!team) {
      return data;
    }

    let dataCopy = _.cloneDeep(data);
    // filter games
    if (_.get(dataCopy, "allGames.nodes")) {
      dataCopy.allGames.nodes = dataCopy.allGames.nodes.filter((node) => {
        return (
          _.toLower(node.frontmatter.homeTeam) === team ||
          _.toLower(node.frontmatter.awayTeam) === team
        );
      });
    }
    // if (_.get(dataCopy, "allHomeGames.nodes")) {
    //   dataCopy.allHomeGames.nodes = dataCopy.allHomeGames.nodes.filter(
    //     (node) => _.toLower(node.frontmatter.homeTeam) === team
    //   );
    // }
    if (_.get(dataCopy, "allGames.edges")) {
      dataCopy.allGames.edges = dataCopy.allGames.edges.filter((edge) => {
        return (
          _.toLower(edge.node.frontmatter.homeTeam) === team ||
          _.toLower(edge.node.frontmatter.awayTeam) === team
        );
      });
    }
    // filter news
    // if (_.get(dataCopy, "headlines.nodes")) {
    //   dataCopy.headlines.nodes = dataCopy.headlines.nodes
    //     .filter((node) => {
    //       return _.toLower(node.frontmatter.team) === team;
    //     })
    //     .slice(0, 4);
    // }
    // if (_.get(dataCopy, "recentNews.nodes")) {
    //   dataCopy.recentNews.nodes = dataCopy.recentNews.nodes
    //     .filter((node) => {
    //       return _.toLower(node.frontmatter.team) === team;
    //     })
    //     .slice(0, 3);
    // }
    // if (_.get(dataCopy, "otherPosts.edges")) {
    //   dataCopy.otherPosts.edges = dataCopy.otherPosts.edges
    //     .filter((edge) => {
    //       return _.toLower(edge.node.frontmatter.team) === team;
    //     })
    //     .slice(0, 3);
    // }
    return dataCopy;
  };

  return (
    <SiteFilterContext.Provider
      value={{
        homeTeam,
        setHomeTeam: updateHomeTeam,
        filterDataByTeam,
      }}
    >
      {children}
    </SiteFilterContext.Provider>
  );
};
